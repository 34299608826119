import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';
import BlankPage from '../routes/BlankPage/BlankPage';
import NgiContentSelector from './NGIContentSelector/NGIContentSelector';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Switch>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank">
            <BlankPage />
          </Route>

          <Route path="/content-selector">
            <NgiContentSelector />
          </Route>
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
