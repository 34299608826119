const CERTIFICATE_TYPE = {
  DEFAULT: '0',
  STANDARD: '1',
  ADVANCED: '2'
};

const REGEX_TYPE = {
  NAME: 'TEST_TAKER_NAME',
  CRN: 'CRN',
  TTN: 'TTN',
  SCORE: 'SCORE',
  CEFR_LEVEL: 'CEFR_LEVEL',
  TEST_CENTER_ID: 'TEST_CENTER_ID'
};

const REGEX = {
  TEST_TAKER_NAME: "^[A-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9-' ]{1,128}$",
  CRN: '^[1-9][0-9]{0,18}$',
  TTN: '^[1-9][0-9]{0,18}$',
  SCORE: '^([1-9]|[1-9][0-9]|1[0-9]{1,2}|200)$',
  CEFR_LEVEL: '^(Below A2|Below B2|[A-C][1-2])$',
  TEST_CENTER_ID: '^[A-Z0-9]{1,10}$'
};

const ERROR_TYPE = {
  EMPTY_FIELD: 'EMPTY_FIELD',
  EMPTY_TEST_TAKER_NAME: 'EMPTY_TEST_TAKER_NAME',
  EMPTY_DOB: 'EMPTY_DOB',
  EMPTY_TEST_TAKER_NUMBER: 'EMPTY_TEST_TAKER_NUMBER',
  EMPTY_CERTIFICATE_REFERENCE_NUMBER: 'EMPTY_CERTIFICATE_REFERENCE_NUMBER',
  EMPTY_OVERALL_SCORE: 'EMPTY_OVERALL_SCORE',
  EMPTY_OVERALL_CEFR_LEVEL: 'EMPTY_OVERALL_CEFR_LEVEL',
  EMPTY_SPEAKING_SCORE: 'EMPTY_SPEAKING_SCORE',
  EMPTY_SPEAKING_CEFR_LEVEL: 'EMPTY_SPEAKING_CEFR_LEVEL',
  EMPTY_SPEAKING_DATE_TAKEN: 'EMPTY_SPEAKING_DATE_TAKEN',
  EMPTY_LISTENING_SCORE: 'EMPTY_LISTENING_SCORE',
  EMPTY_LISTENING_CEFR_LEVEL: 'EMPTY_LISTENING_CEFR_LEVEL',
  EMPTY_LISTENING_DATE_TAKEN: 'EMPTY_LISTENING_DATE_TAKEN',
  EMPTY_READING_SCORE: 'EMPTY_READING_SCORE',
  EMPTY_READING_CEFR_LEVEL: 'EMPTY_READING_CEFR_LEVEL',
  EMPTY_READING_DATE_TAKEN: 'EMPTY_READING_DATE_TAKEN',
  EMPTY_WRITING_SCORE: 'EMPTY_WRITING_SCORE',
  EMPTY_WRITING_CEFR_LEVEL: 'EMPTY_WRITING_CEFR_LEVEL',
  EMPTY_WRITING_DATE_TAKEN: 'EMPTY_WRITING_DATE_TAKEN',
  INVALID_FORMAT: 'INVALID_FORMAT',
  INVALID_DATE_PERIOD: 'INVALID_DATE_PERIOD',
  INVALID_CERTIFICATE_TYPE: 'INVALID_CERTIFICATE_TYPE',
  INVALID_CEFR_LEVEL: 'INVALID_CEFR_LEVEL',
  INVALID_CRN: 'INVALID_CRN'
};

const SUBMIT_TIMEOUT = 30000;

export default { CERTIFICATE_TYPE, REGEX, REGEX_TYPE, ERROR_TYPE, SUBMIT_TIMEOUT };
