import React from 'react';
import PropTypes from 'prop-types';
import { ICON_RIGHT, ICON_LEFT } from '@oup/shared-front-end/src/svg/oup/index.js';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { usePagination, DOTS } from './usePagination';
import styles from './Pagination.scss';

function Pagination({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={styles.container}>
      <Button
        variant="outline"
        icon={{
          component: <ICON_LEFT />
        }}
        size="small"
        disabled={currentPage === 1}
        onClick={onPrevious}
      />
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <div className={styles.paginationDiv} key={index}>
              &#8230;
            </div>
          );
        }

        return (
          <Button
            key={index}
            variant={pageNumber === currentPage ? 'filled' : 'outline'}
            text={pageNumber.toString()}
            size="small"
            onClick={() => onPageChange(pageNumber)}
          />
        );
      })}
      <Button
        variant="outline"
        icon={{
          component: <ICON_RIGHT />
        }}
        size="small"
        disabled={currentPage === lastPage}
        onClick={onNext}
      />
    </div>
  );
}

Pagination.propTypes = {
  siblingCount: PropTypes.string,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number
};

export default Pagination;
