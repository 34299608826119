import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconErrorCircle from './icon-error-circle-24.svg';

// @ts-ignore
import styles from './TextInputField.scss';

function prefixKeys(object, prefix) {
  return (
    object &&
    Object.keys(object).reduce((result, key) => {
      const attrName = key.indexOf(prefix) === 0 ? key : `${prefix}${key}`;
      // eslint-disable-next-line no-param-reassign
      result[attrName] = object[key];
      return result;
    }, {})
  );
}

/** This component is for the input field only. It should always be used with TextInputFieldLabel. */
class TextInputField extends Component {
  componentDidUpdate(prevProps) {
    const { autoFocus } = this.props;
    if (!prevProps.autoFocus && autoFocus) {
      this._input.focus();
    }
  }

  // The input debounce function, called when typing has stopped
  _updateInput(input, callback) {
    this._updateInput = this._updateInput.bind(this);
    // eslint-disable-next-line no-unused-expressions
    input !== '' ? callback(input) : false;
  }

  // Note: The only reason we wrap this in a <div> is to make the tooltip css work.
  // It relies on css :before and :after which cannot be applied to <input> elements.
  render() {
    const {
      type = 'text',
      inputmode = null,
      autocomplete = 'on',
      autoCapitalize,
      isSmall,
      id,
      // name, // added to props
      title,
      value,
      prefix,
      // placeholder, // added to props
      disabled = false,
      required = true,
      // min, // added to props
      // max, // added to props
      // maxLength, // added to props
      // autoFocus, // used outside of render
      autoHighlight = false,

      isValid = undefined,

      textArea = false,
      readOnly = false,
      readOnlyPreserveStyle = true,

      customStyleName,
      aria,
      // role, // added to props

      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyUp,
      onTypeStop,
      onKeyDown
    } = this.props;

    const _onChange = ({ target }) => {
      if (onTypeStop) this._updateInput(target.value, onTypeStop);
      if (onChange) onChange(target.value, target.name);
    };

    const _onFocus = ({ target }) => {
      if (onFocus) onFocus(target.name);
    };

    // If aria attributes were supplied, ensure they all have a prefix of "aria-":
    const ariaAttrs = prefixKeys(aria, 'aria-');

    const Tag = textArea ? 'textarea' : 'input';

    return (
      <div title={title} className={`${styles.textInputFieldWrapper} `}>
        {readOnly ? (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              id={id}
              onKeyDown={onClick ? () => onClick() : undefined}
              onClick={onClick ? () => onClick() : undefined}
              className={classnames({
                [styles.textInputField]: readOnlyPreserveStyle
              })}
            >
              {prefix !== undefined ? <span className={classnames(styles.prefix)}>{prefix}</span> : ''}
              {readOnlyPreserveStyle ? value : value || 'n/a'}
            </div>
          </>
        ) : (
          <>
            <Tag
              ref={input => {
                this._input = input;
              }}
              type={type}
              inputMode={inputmode}
              autoComplete={autocomplete}
              autoCapitalize={autoCapitalize}
              id={id}
              prefix={prefix}
              value={value}
              disabled={disabled}
              required={required}
              aria-invalid={isValid === false}
              aria-describedby={isValid === false ? `${id}-message` : null}
              className={classnames(
                styles.textInputField,
                {
                  [styles.textArea]: textArea,
                  [styles.validated]: isValid !== undefined,
                  [styles.valid]: isValid === true,
                  [styles.invalid]: isValid === false,
                  [styles.warning]: isValid === 'warning',
                  [styles.small]: isSmall === true
                },
                customStyleName
              )}
              onChange={_onChange}
              onClick={onClick ? event => onClick(event.target.value) : undefined}
              onKeyUp={onKeyUp}
              onFocus={autoHighlight && !onFocus ? event => event.target.select() : _onFocus}
              onBlur={onBlur ? event => onBlur(event.target.name) : undefined}
              onKeyDown={onKeyDown}
              {...ariaAttrs}
            />
            {isValid === false && <IconErrorCircle className={styles.validationIcon} />}
          </>
        )}
      </div>
    );
  }
}

TextInputField.propTypes = {
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  type: PropTypes.oneOf([
    'text',
    'search',
    'tel',
    'url',
    'email',
    'number',
    'password',
    'date',
    'time',
    'datetime-local',
    'month'
  ]),
  /** suggest which keyboard to use on mobile devices (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode) */
  inputmode: PropTypes.oneOf(['text', 'search', 'tel', 'url', 'email', 'numeric', 'decimal', 'none']),
  /** Specify which autocomplete information to use boolean, name, personal info, telephone, authentication, address, credit card (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) */
  autocomplete: PropTypes.oneOf([
    'on',
    'off',

    'name',
    'honorific-prefix',
    'given-name',
    'additional-name',
    'family-name',
    'honorific-suffix',
    'nickname',
    'cc-name',
    'cc-given-name',
    'cc-additional-name',
    'cc-family-name',

    'bday',
    'bday-day',
    'bday-month',
    'bday-year',
    'sex',
    'photo',

    'tel',
    'tel-country-code',
    'tel-national',
    'tel-area-code',
    'tel-local',
    'tel-extension',

    'organization',
    'street-address',
    'address-level1',
    'address-level2',
    'address-level3',
    'address-level4',
    'country',
    'country-name',
    'postal-code',

    'email',
    'username',
    'new-password',
    'current-password',
    'one-time-code',

    'cc-name',
    'cc-given-name',
    'cc-additional-name',
    'cc-family-name',
    'cc-number',
    'cc-exp',
    'cc-exp-month',
    'cc-exp-year',
    'cc-csc',
    'cc-type',
    'transaction-currency',
    'transaction-amount'
  ]),
  /** Controls whether and how text input is automatically capitalized as it is entered/edited on mobile devices */
  autoCapitalize: PropTypes.oneOf(['off', 'on', 'words', 'characters', 'email']),
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** is the input the same height as small button? */
  isSmall: PropTypes.bool,
  /** name attribute */
  name: PropTypes.string.isRequired,
  /** The optional tooltip */
  title: PropTypes.string,
  /** Bold text to be used in read only */
  prefix: PropTypes.string,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** the inputs placeholder text (destructured into props) */
  placeholder: PropTypes.string,
  /** Set this to true to disable any interaction on the input field */
  disabled: PropTypes.bool,
  /** Set this to false to prevent text box from defaulting to required, for screen readers etc */
  required: PropTypes.bool,
  /** the minimum limit for numeric fields  (destructured into props) */
  min: PropTypes.number,
  /** the maximum limit for numeric fields  (destructured into props) */
  max: PropTypes.number,
  /** Max Length value for the input element */
  maxLength: PropTypes.number,
  /** Auto focus this input */
  autoFocus: PropTypes.bool,
  /** If no onFocus prop will select input contents on focus */
  autoHighlight: PropTypes.bool,
  /** if undefined input has not been validated, if true/false svg icon and colors are applied via css */
  isValid: PropTypes.oneOf([true, false, 'warning', undefined]),
  /** change then input to a textarea element so we can have multi line */
  textArea: PropTypes.bool,
  /** Set whether the field is for presentation and is not editable. */
  readOnly: PropTypes.bool,
  /** Apply input styling  */
  readOnlyPreserveStyle: PropTypes.bool,
  /** Give the text box a custom style */
  customStyleName: PropTypes.string,
  /** map of aria attribute names and values, eg: aria={  live: 'assertive' } If they don't start with 'aria-' it is added so it is not suitable for adding role which will become 'aria-role' */
  aria: PropTypes.object,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func,
  /** onClick handler */
  onClick: PropTypes.func,
  /** onFocus handler */
  onFocus: PropTypes.func,
  /** Function to bind to the native input onKeyDown event */
  onKeyDown: PropTypes.func,
  /** Function to bind to the native input onKeyUp event */
  onKeyUp: PropTypes.func,
  /** function that fires when typing stops */
  onTypeStop: PropTypes.func
};

export default TextInputField;
