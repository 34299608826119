import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function LmsSubmitForm({ url, jwt }) {
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.submit();
  });

  return (
    <form method="POST" action={url} ref={formRef}>
      <input type="hidden" name="JWT" value={jwt} />
    </form>
  );
}

LmsSubmitForm.propTypes = {
  url: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired
};

export default LmsSubmitForm;
