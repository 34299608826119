import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage/ValidationMessage.js';
import classNames from 'classnames';
import {
  filterProducts,
  fetchProductsRequest,
  saveSearchedValue
} from '../../../redux/actions/getOpenProductsActions.js';
import { setSelectedProducts, setConfirmedProductsBackup } from '../../../redux/actions/ngiContentSelectorActions.js';
import PanelHeading from '../../../components/PanelHeading/PanelHeading.js';
import styles from './ProductsPage.scss';
import ProductsList from './ProductsList.js';
import ContentSelectorSearchInput from './SearchInput.js';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner.js';
import ErrorView from '../components/ErrorView.js';
import Footer from './SelectionFooter.js';
import NoResultsFound from './NoResultsFound.js';

function ProductsPage({ onNextClick }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductsRequest());
  }, []);

  const { loading, error, filteredProducts, searchedValue } = useSelector(state => state.getOpenProducts);
  const { selectedProducts, lmsConfig, confirmedProducts, confirmedProductsBackup } = useSelector(
    state => state.ngiContentSelector
  );
  const [search, setSearch] = useState(searchedValue);

  const debouncedSearch = useCallback(
    debounce(searchValue => {
      dispatch(saveSearchedValue(searchValue));
      dispatch(filterProducts(searchValue));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!loading && !error && filteredProducts.length > 0) {
      debouncedSearch(searchedValue);
    }
  }, []);

  useEffect(() => {
    const remainedProductsInBackup = confirmedProductsBackup.filter(backProduct =>
      confirmedProducts.some(product => product.isbnLaunch === backProduct.isbnLaunch)
    );

    dispatch(setConfirmedProductsBackup([...remainedProductsInBackup]));
  }, []);

  const onSearchTermChanged = term => {
    debouncedSearch(term.trim());
    setSearch(term);
  };

  const handleTryAgainButtonClick = useCallback(() => {
    dispatch(fetchProductsRequest());
  }, [dispatch]);

  const handleOnSingleProductSelected = () => {
    onNextClick();
  };
  const handleOnClearClick = () => {
    dispatch(setSelectedProducts([]));
  };

  const handleOnResetFiltersClick = () => {
    setSearch('');
    dispatch(filterProducts(''));
  };

  return (
    <div
      className={classNames(
        !lmsConfig.acceptMultiple ? styles.selectionContainer : styles.container,
        selectedProducts?.length && styles.containerWithSelectedProducts
      )}
    >
      <PanelHeading
        customClassName={styles.panel}
        title="Find learning materials"
        subtitle="Choose learning materials for your course"
        isPrimaryTitle
      />

      {!lmsConfig.acceptMultiple && (
        <div className={styles.message}>
          <ValidationMessage state="information">
            You can only add one item from an External Tool to <i>Assignments</i> in Canvas. To add multiple content and
            grade items select the External Tool from the <i>Modules</i>.
          </ValidationMessage>
        </div>
      )}

      {!lmsConfig.gradeSyncEnabled && (
        <div className={styles.message}>
          <ValidationMessage state="information">
            <span>Adding Grade items has been turned off&nbsp;&nbsp;</span>
          </ValidationMessage>
        </div>
      )}

      {loading && !error && <LoadingSpinner customClass={styles.loading} />}

      {!loading && error && (
        <div className={styles.error}>
          <ErrorView
            title="Something went wrong"
            bodyText="We're sorry for the inconvenience"
            hasButton
            onButtonClick={handleTryAgainButtonClick}
          />
        </div>
      )}

      {!loading && !error && (
        <ContentSelectorSearchInput
          value={search}
          onValueChange={onSearchTermChanged}
          placeholder="Search by title, author, platform, ISBN or level"
        />
      )}

      {!loading && !error && filteredProducts.length > 0 ? (
        <ProductsList
          acceptMultiple={lmsConfig.acceptMultiple}
          products={filteredProducts}
          onSingleProductSelected={handleOnSingleProductSelected}
        />
      ) : (
        !loading && !error && <NoResultsFound onResetFiltersClick={handleOnResetFiltersClick} />
      )}

      {lmsConfig.acceptMultiple && (
        <Footer
          selectedCount={selectedProducts?.length || 0}
          onClearClick={handleOnClearClick}
          onNextClick={onNextClick}
        />
      )}
    </div>
  );
}

ProductsPage.propTypes = {
  onNextClick: PropTypes.func
};

export default ProductsPage;
