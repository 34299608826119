import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductsPage from './ProductsPage/ProductsPage.js';
import styles from './NGIContentSelector.scss';
import ContentSelectorHeader from './components/Header.js';
import { setLmsConfig, setSelectedProducts } from '../../redux/actions/ngiContentSelectorActions.js';
import ConfirmationPage from './ConfirmationPage/ConfirmationPage.js';
import ProductDetailsModal from './components/ProductDetailsModal.js';
import GroupRetryOptionsPage from './GroupRetryOptionsPage/GroupRetryOptionsPage.js';
import SubmitPage from './SubmitPage/SubmitPage.js';
import LineItemCreationProgressPage from './LineItemCreationProgressPage/LineItemCreationProgressPage.js';
import CreateDeepLinksPage from './CreateDeepLinksPage/CreateDeepLinksPage.js';

function NgiContentSelector() {
  const dispatch = useDispatch();
  const [mode, setMode] = useState('group-retry-options');
  const {
    selectedProducts,
    confirmedProducts,
    productDetailsModal,
    lmsConfig: { token }
  } = useSelector(state => state.ngiContentSelector);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const passedToken = urlParams.get('token');
    const acceptMultiple = urlParams.get('acceptMultiple') === 'true';
    const gradeSyncEnabled = urlParams.get('gradeSyncEnabled') === 'true';

    dispatch(setLmsConfig(acceptMultiple, gradeSyncEnabled, passedToken));

    if (passedToken) {
      sessionStorage.setItem('token', passedToken);
    }
  }, []);

  const handleOnBackClick = () => {
    const selectedProductsBeforeBackIsClicked = selectedProducts.filter(selectedProduct =>
      confirmedProducts.some(confirmedProduct => confirmedProduct.isbnLicense === selectedProduct.isbn)
    );

    dispatch(setSelectedProducts(selectedProductsBeforeBackIsClicked));
    setMode('selection');
  };

  const handleGradeCreation = () => {
    setMode('line-item-creation-progress');
  };

  const handleLinkCreationPage = () => {
    setMode('link-creation');
  };

  const handleOnNextClick = () => {
    setMode('confirm');
  };

  const submitData = () => {
    setMode('submit');
  };

  const handleOnResumeClick = () => {
    setMode('line-item-creation-progress');
  };

  const handleEditMode = () => {
    setMode('selection');
  };

  return (
    <div>
      {token && (
        <>
          <ContentSelectorHeader />
          <div className={styles.container}>
            <div className={styles.page}>
              {mode === 'group-retry-options' && (
                <GroupRetryOptionsPage
                  onResumeClick={handleOnResumeClick}
                  editMode={handleEditMode}
                  deepLinkScreen={handleLinkCreationPage}
                />
              )}
              {mode === 'selection' && <ProductsPage onNextClick={handleOnNextClick} />}
              {mode === 'confirm' && (
                <ConfirmationPage
                  onBackClick={handleOnBackClick}
                  gradeCreation={handleGradeCreation}
                  deepLinkScreen={handleLinkCreationPage}
                />
              )}
              {mode === 'line-item-creation-progress' && <LineItemCreationProgressPage />}
              {mode === 'link-creation' && <CreateDeepLinksPage submitData={submitData} />}
              {mode === 'submit' && <SubmitPage />}
            </div>
          </div>
          {productDetailsModal.isOpen && <ProductDetailsModal />}
        </>
      )}
    </div>
  );
}

export default NgiContentSelector;
