import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup/index.js';
import { PLATFORMS } from '../../../../sharedNodeBrowser/constants.js';
import Badge from '../../../components/Badge/Badge';
import colors from '../../../globals/colors';
import styles from './ConfirmationFooter.scss';
import SelectionStatus from '../ProductsPage/SelectionStatus';

function ConfirmationFooter({ onBackClick, onSubmit, showActionsRow, isSubmitButtonDisabled }) {
  const { loading } = useSelector(state => state.getLinkedProducts);
  const { confirmedProducts } = useSelector(state => state.ngiContentSelector);

  const numberOfProductsWithoutSelectedItems = confirmedProducts.filter(product => !product.addContentItems).length;

  const numberOfSelectedPages = confirmedProducts.reduce((memo, product) => {
    const noProductErrors = !product.errors || !Object.values(product.errors).find(e => !!e);
    if (product.platform === PLATFORMS.OLB && product.addContentItems && noProductErrors) {
      return memo + product.selectedEndPage - product.selectedStartPage + 1;
    }

    return memo;
  }, 0);

  const numberOfSelectedActivities = confirmedProducts
    .filter(product => product.platform === 'VST' && product.addContentItems)
    .reduce(
      (productSum, product) =>
        productSum +
        ((product.addContentItems && product.includeOnlineClassroom && 1) || 0) +
        (product.vstUnits && product.addContentItems
          ? product.vstUnits.reduce(
              (unitSum, unit) =>
                unitSum +
                unit.vstLessons.reduce(
                  (lessonSum, lesson) =>
                    lessonSum +
                    lesson.vstActivities.reduce(
                      (activitySum, activity) => (activity.isChecked ? activitySum + 1 : activitySum),
                      0
                    ),
                  0
                ),
              0
            )
          : 0),
      0
    );

  const linksCount = numberOfProductsWithoutSelectedItems + numberOfSelectedActivities + numberOfSelectedPages;

  const handleAddLearningMaterialsButtonClick = () => {
    onSubmit();
  };

  // This flag detects if there is at least one product which has addContentItems field enabled but all lessons are unselected
  const vstProductWithUncheckedItems = confirmedProducts.some(
    product =>
      product.platform === 'VST' &&
      product.addContentItems &&
      !product.includeOnlineClassroom &&
      !product.vstUnits.some(unit =>
        unit.vstLessons.some(lesson => lesson.vstActivities.some(activity => activity.isChecked))
      )
  );

  return (
    <div className={styles.container}>
      {!loading && showActionsRow && (
        <div className={styles.select}>
          <span>
            <Badge backgroundColor={colors.PRIMARY_BLUE} value={linksCount} /> Links
          </span>
          <SelectionStatus linksCount={linksCount} />
        </div>
      )}

      <div className={styles.confirmation}>
        <Button
          variant="transparent"
          text="Back"
          size="base"
          icon={{
            placement: 'left',
            component: <ICON_LEFT />
          }}
          onClick={onBackClick}
        />
        <Button
          variant="filled"
          text="Add learning materials"
          size="base"
          onClick={handleAddLearningMaterialsButtonClick}
          disabled={!!isSubmitButtonDisabled || vstProductWithUncheckedItems}
        />
      </div>
    </div>
  );
}

ConfirmationFooter.propTypes = {
  onBackClick: PropTypes.func,
  showActionsRow: PropTypes.bool,
  onSubmit: PropTypes.func,
  isSubmitButtonDisabled: PropTypes.oneOf(PropTypes.bool, PropTypes.number)
};

export default ConfirmationFooter;
