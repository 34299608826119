import React from 'react';
import PropTypes from 'prop-types';

import TextInputField from './TextInputField';
import InputLabel from './InputLabel';

/**
 * A text input label and field.
 */
function TextInput({
  label,
  labelHidden,
  labelSub,

  type = 'text',
  inputmode = 'text',
  autocomplete = 'on',
  autoCapitalize,
  id,
  name,
  title,
  prefix,
  value,
  placeholder,
  disabled,
  required,
  min,
  max,
  maxLength,
  autoHighlight,
  autoFocus = false,

  isValid,
  validationMessage,

  textArea = false,
  readOnly = false,
  readOnlyPreserveStyle = false,

  customStyleName,
  aria,

  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  onTypeStop
}) {
  return (
    <InputLabel
      label={label}
      labelHidden={labelHidden}
      labelSub={labelSub}
      id={id}
      required={required}
      isValid={isValid}
      validationMessage={validationMessage}
      disabled={disabled}
    >
      <TextInputField
        type={type}
        inputmode={inputmode}
        autoComplete={autocomplete}
        autoCapitalize={autoCapitalize}
        id={id}
        name={name}
        title={title}
        prefix={prefix}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        min={min}
        max={max}
        maxLength={maxLength}
        autoHighlight={autoHighlight}
        autoFocus={autoFocus}
        isValid={isValid}
        textArea={textArea}
        readOnly={readOnly}
        readOnlyPreserveStyle={readOnlyPreserveStyle}
        customStyleName={customStyleName}
        aria={aria}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onTypeStop={onTypeStop}
      />
    </InputLabel>
  );
}
TextInput.propTypes = {
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** The label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** extra supporting label text */
  labelSub: PropTypes.string,
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  type: PropTypes.oneOf([
    'text',
    'search',
    'tel',
    'url',
    'email',
    'number',
    'password',
    'date',
    'time',
    'datetime-local',
    'month'
  ]),
  /** sugguest which keyboard to use on mobile devices (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode) */
  inputmode: PropTypes.oneOf(['text', 'search', 'tel', 'url', 'email', 'numeric', 'decimal', 'none']),
  /** the id of the input field needs to be unique */
  /** Specify which autocomplete information to use boolean, name, personal info, telephone, authentication, address, credit card (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) */
  autocomplete: PropTypes.oneOf([
    'on',
    'off',

    'name',
    'honorific-prefix',
    'given-name',
    'additional-name',
    'family-name',
    'honorific-suffix',
    'nickname',
    'cc-name',
    'cc-given-name',
    'cc-additional-name',
    'cc-family-name',

    'bday',
    'bday-day',
    'bday-month',
    'bday-year',
    'sex',
    'photo',

    'tel',
    'tel-country-code',
    'tel-national',
    'tel-area-code',
    'tel-local',
    'tel-extension',

    'organization',
    'street-address',
    'address-level1',
    'address-level2',
    'address-level3',
    'address-level4',
    'country',
    'country-name',
    'postal-code',

    'email',
    'username',
    'new-password',
    'current-password',
    'one-time-code',

    'cc-name',
    'cc-given-name',
    'cc-additional-name',
    'cc-family-name',
    'cc-number',
    'cc-exp',
    'cc-exp-month',
    'cc-exp-year',
    'cc-csc',
    'cc-type',
    'transaction-currency',
    'transaction-amount'
  ]),
  /**  Controls whether and how text input is automatically capitalized as it is entered/edited on mobile devices */
  autoCapitalize: PropTypes.oneOf(['off', 'on', 'words', 'characters', 'email']),
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** name attribute */
  name: PropTypes.string.isRequired,
  /** The optional tooltip */
  title: PropTypes.string,
  /** Bold text to be used in read only */
  prefix: PropTypes.string,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** the inputs placeholder text (destructured into props) */
  placeholder: PropTypes.string,
  /** Set this to true to disable any interaction on the input field */
  disabled: PropTypes.bool,
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  required: PropTypes.bool,

  /** the minumum limit for numeric fields  (destructured into props) */
  min: PropTypes.number,
  /** the maximum limit for numeric fields  (destructured into props) */
  max: PropTypes.number,
  /** Max Length value for the input element */
  maxLength: PropTypes.number,
  /** Auto focus this input */
  autoFocus: PropTypes.bool,
  /** If no onFocus prop will select input contents on focus */
  autoHighlight: PropTypes.bool,

  /** if undefined input has not been validated, if true/false svg icon and colors are applied via css */
  isValid: PropTypes.oneOf([true, false, 'warning']),
  /** The error or confirmation message */
  validationMessage: PropTypes.string,

  /** Make the text box of type text area */
  textArea: PropTypes.bool,
  /** Set whether the field is for presentation and is not editable. */
  readOnly: PropTypes.bool,
  /** Apply input styling  */
  readOnlyPreserveStyle: PropTypes.bool,

  /** Give the textbox a custom style */
  customStyleName: PropTypes.string,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,

  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func,
  /** onClick handler */
  onClick: PropTypes.func,
  /** onFocus handler */
  onFocus: PropTypes.func,
  /** Function to bind to the native input onKeyDown event */
  onKeyDown: PropTypes.func,
  /** Function to bind to the native input onKeyUp event */
  onKeyUp: PropTypes.func,
  /** function that fires when typing stops */
  onTypeStop: PropTypes.func
};

export default TextInput;
