import * as t from '../actionTypes';

export const setSelectedProducts = products => ({
  type: t.NGI_SET_SELECTED_PRODUCTS,
  payload: products
});

export const showProductDetailsModal = product => ({
  type: t.NGI_SHOW_PRODUCT_DETAILS_MODAL,
  payload: product
});

export const hideProductDetailsModal = () => ({
  type: t.NGI_CLOSE_PRODUCT_DETAILS_MODAL
});

export const setLmsConfig = (acceptMultiple, gradeSyncEnabled, token) => ({
  type: t.NGI_SET_LMS_CONFIG,
  payload: { acceptMultiple, gradeSyncEnabled, token }
});

export const setConfirmedProducts = products => ({
  type: t.NGI_SET_CONFIRMED_PRODUCTS,
  payload: products
});

export const setConfirmedProductsBackup = confirmedProductsBackup => ({
  type: t.NGI_SET_CONFIRMED_PRODUCTS_BACKUP,
  payload: confirmedProductsBackup
});

export const setStartAndEndPage = payload => ({
  type: t.NGI_SET_OLB_START_END_PAGE,
  payload
});
