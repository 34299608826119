import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconCheck from './icon-check-24.svg';
import styles from './Toggle.scss';

function Toggle({ onToggle, id = '', label = '', disabled = false, IconOn, IconOff, toggled = false }) {
  const handleOnToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onToggle();
  };

  return (
    <>
      {label && (
        <label className={styles.toggleLabel} htmlFor={id}>
          {label}
        </label>
      )}
      <button
        id={id}
        className={classnames(
          styles.bar,
          { [styles.on]: toggled },
          { [styles.off]: !toggled },
          {
            [styles.disabled]: disabled
          }
        )}
        onClick={handleOnToggle}
        disabled={disabled}
        type="button"
        role="switch"
        value={toggled}
        aria-checked={toggled}
      >
        <span className={styles.circle}>
          <span className={styles.icon}>
            {toggled && !IconOn && <IconCheck />}
            {toggled && IconOn && <IconOn />}
            {!toggled && IconOff && <IconOff />}
          </span>
        </span>
      </button>
    </>
  );
}

Toggle.propTypes = {
  onToggle: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  IconOn: PropTypes.any,
  IconOff: PropTypes.any,
  toggled: PropTypes.bool
};

export default Toggle;
