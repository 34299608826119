import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage/ValidationMessage.js';
import { fetchLinkedProductsRequest } from '../../../redux/actions/getLinkedProductsActions.js';
import { getJwtAndDeepLinkRequest } from '../../../redux/actions/getJwtAndDeeplinkReturnUrlActions.js';
import { storeLineItemsRequest } from '../../../redux/actions/storeLineItemsActions.js';
import { setConfirmedProducts, setConfirmedProductsBackup } from '../../../redux/actions/ngiContentSelectorActions.js';
import PanelHeading from '../../../components/PanelHeading/PanelHeading.js';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner.js';
import ErrorView from '../components/ErrorView.js';
import styles from './ConfirmationPage.scss';
import ConfirmationProductList from './ConfirmationProductList.js';
import ConfirmationFooter from './ConfirmationFooter.js';

function ConfirmationPage({ onBackClick, gradeCreation, deepLinkScreen }) {
  const dispatch = useDispatch();

  const { loading, error, products } = useSelector(state => state.getLinkedProducts);
  const { loading: jwtLoading, error: jwtError } = useSelector(state => state.getJwtAndDeeplinkReturnUrl);
  const { loading: lineStoreLoading } = useSelector(state => state.processLineItemsBatches);
  const { selectedProducts, lmsConfig, confirmedProducts, confirmedProductsBackup } = useSelector(
    state => state.ngiContentSelector
  );

  const errors = confirmedProducts.filter(
    product => product.addContentItems && product.errors && Object.values(product.errors).find(e => !!e)
  ).length;

  useEffect(() => {
    dispatch(fetchLinkedProductsRequest(selectedProducts.map(product => product.productid)));
  }, [selectedProducts]);

  useEffect(() => {
    if (products.length === 0) return;
    // leaving verbose code below to highlight the scenarios

    if (lmsConfig.acceptMultiple) {
      const newSavedProducts = products.map(item => ({ ...item }));

      if (confirmedProductsBackup.length === 0) {
        const saveSelectedProducts = products.map(item => ({ ...item }));
        dispatch(setConfirmedProductsBackup(saveSelectedProducts));
        dispatch(setConfirmedProducts(newSavedProducts));
      } else {
        const newProducts = products.filter(
          product =>
            !confirmedProductsBackup.some(confirmedProduct => confirmedProduct.isbnLaunch === product.isbnLaunch)
        );
        const remainedProductsInBackup = confirmedProductsBackup.filter(backProduct =>
          products.some(product => product.isbnLaunch === backProduct.isbnLaunch)
        );
        const changeRemainedProductsInBackupToggles = remainedProductsInBackup.map(item => ({
          ...item
        }));
        dispatch(setConfirmedProductsBackup([...confirmedProductsBackup, ...newProducts]));
        dispatch(setConfirmedProducts([...changeRemainedProductsInBackupToggles, ...newProducts]));
      }
      return;
    }
    if (!lmsConfig.acceptMultiple && products.length === 1) {
      // lms accepts single deep link && a single product is selected
      dispatch(setConfirmedProducts([products[0]]));
      return;
    }
    if (!lmsConfig.acceptMultiple && products.length > 1) {
      // lms accepts single deep link && a pack is selected
      dispatch(setConfirmedProducts([]));
      // return;
    }
  }, [products, setConfirmedProducts, dispatch]);

  const handleTryAgainButtonClick = useCallback(() => {
    dispatch(fetchLinkedProductsRequest(selectedProducts.map(product => product.productid)));
  }, [dispatch, lmsConfig]);

  const startProcessLineItems = ({ createLineItemsResponse }) => {
    const productsWithGradeItems = confirmedProducts.filter(p => p.addGradeItems);
    if (productsWithGradeItems.length === 0) {
      deepLinkScreen();
    } else {
      gradeCreation();
      dispatch(
        storeLineItemsRequest(
          {
            productsWithGradeItems,
            config: { ...createLineItemsResponse }
          },
          () => {
            localStorage.removeItem('items');
            deepLinkScreen();
          }
        )
      );
    }
  };

  const extractVSTSelectedLinks = product => {
    const selectedLinks = product.vstUnits.reduce(
      (accUnit, unit) => [
        ...accUnit,
        ...unit.vstLessons.reduce(
          (accLessons, lesson) => [
            ...accLessons,
            ...lesson.vstActivities.reduce(
              (accActivities, activity) =>
                activity.isChecked ? [...accActivities, { activityId: activity.uId }] : accActivities,
              []
            )
          ],
          []
        )
      ],
      []
    );

    if (product.includeOnlineClassroom) {
      selectedLinks.unshift({
        isClassRoom: true
      });
    }
    return selectedLinks;
  };

  const createDeepLinksAndGetJwt = () => {
    const items = confirmedProducts.map(product => ({
      isbnLaunch: product.isbnLaunch,
      isbnLicense: product.isbnLicense,
      addContentItems: !!product.addContentItems,
      addGradeItems: !!product.addGradeItems,
      ...(product.addContentItems &&
        product.vstUnits && {
          selectedLinks: extractVSTSelectedLinks(product)
        }),
      ...(product.addContentItems &&
        product.olbDetails && {
          startPage: product.selectedStartPage,
          endPage: product.selectedEndPage
        })
    }));

    localStorage.setItem('items', JSON.stringify(items));

    dispatch(getJwtAndDeepLinkRequest({ token: lmsConfig.token, items }, startProcessLineItems));
  };

  const hasDeepLinks = confirmedProducts.find(product => product.addContentItems);

  const getConfirmationProductListClassName = () => {
    if (lmsConfig.acceptMultiple) {
      if (hasDeepLinks) {
        return styles.panelConfirmationWithDeepLinks;
      }
      if (confirmedProducts.length === 0) {
        return styles.panelConfirmationWithNoLinks;
      }
      return styles.panelConfirmation;
    }
    return styles.panelSingleItemConfirmation;
  };

  return (
    <div
      className={classNames(styles.container, jwtLoading && styles.dataLoadingInProgress)}
      inert={jwtLoading || lineStoreLoading ? '' : null}
    >
      {!jwtError && (
        <PanelHeading
          customClassName={styles.panel}
          title="Add these materials?"
          subtitle="Check your materials below before adding them to your course"
          isPrimaryTitle
        />
      )}

      {!jwtError && !lmsConfig.acceptMultiple && (
        <div className={styles.message}>
          <ValidationMessage state="information">
            You can only add one item from an External Tool to <i>Assignments</i> in Canvas. To add multiple content and
            grade items select the External Tool from the <i>Modules</i>.
          </ValidationMessage>
        </div>
      )}

      {!jwtError && !lmsConfig.gradeSyncEnabled && (
        <div className={styles.message}>
          <ValidationMessage state="information">
            <span>Adding Grade items has been turned off&nbsp;&nbsp;</span>
          </ValidationMessage>
        </div>
      )}

      {loading && !error && <LoadingSpinner customClass={styles.loading} />}

      {!loading && error && (
        <div className={!lmsConfig.acceptMultiple ? styles.singleItemError : styles.multipleItemsError}>
          <ErrorView
            title="Something went wrong"
            bodyText="We're sorry for the inconvenience"
            hasButton
            onButtonClick={handleTryAgainButtonClick}
          />
        </div>
      )}

      {!loading && !error && !jwtError && products.length && (
        <div className={getConfirmationProductListClassName()}>
          <ConfirmationProductList />
        </div>
      )}

      {!jwtError && (
        <ConfirmationFooter
          showActionsRow={lmsConfig.acceptMultiple}
          isSubmitButtonDisabled={errors || loading || confirmedProducts.length === 0 || jwtLoading || lineStoreLoading}
          onBackClick={onBackClick}
          onSubmit={createDeepLinksAndGetJwt}
        />
      )}

      {jwtError && (
        <div className={!lmsConfig.acceptMultiple ? styles.singleItemError : styles.multipleItemsError}>
          <ErrorView
            title="Something went wrong"
            bodyText="We're sorry for the inconvenience"
            additionalText="Please relaunch the content selector"
            centerOnPage
          />
        </div>
      )}
    </div>
  );
}

ConfirmationPage.propTypes = {
  onBackClick: PropTypes.func,
  gradeCreation: PropTypes.func,
  deepLinkScreen: PropTypes.func
};

export default ConfirmationPage;
