import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from './Pagination';
import SingleItem from './SelectionLineItem';
import styles from './ProductsList.scss';
import { setSelectedProducts } from '../../../redux/actions/ngiContentSelectorActions';

const PAGE_SIZE = 10;

function ProductList({ products, acceptMultiple, onSingleProductSelected }) {
  const dispatch = useDispatch();
  const { selectedProducts } = useSelector(state => state.ngiContentSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [products]);

  const productsOnPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return products.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, products]);

  const handleOnProductSelected = product => {
    dispatch(setSelectedProducts([...selectedProducts, product]));
  };
  const handleOnProductDeselected = product => {
    dispatch(setSelectedProducts(selectedProducts.filter(p => p.productid !== product.productid)));
  };

  const handleSingleProductSelected = product => {
    dispatch(setSelectedProducts([product]));
    onSingleProductSelected(product);
  };

  return (
    <div className={styles.container}>
      <ul>
        {productsOnPage.map(product => (
          <SingleItem
            key={product.productid}
            product={product}
            acceptMultiple={acceptMultiple}
            onProductSelected={handleOnProductSelected}
            onProductDeselected={handleOnProductDeselected}
            onSingleProductSelected={handleSingleProductSelected}
            isProductSelected={selectedProducts.findIndex(p => p.productid === product.productid) !== -1}
          />
        ))}
      </ul>
      <Pagination
        currentPage={currentPage}
        totalCount={products.length}
        pageSize={PAGE_SIZE}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
ProductList.propTypes = {
  products: PropTypes.array,
  acceptMultiple: PropTypes.bool,
  onSingleProductSelected: PropTypes.func
};
export default ProductList;
