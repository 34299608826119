import React from 'react';
import { useSelector } from 'react-redux';
import styles from './SubmitPage.scss';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import LmsSubmitForm from './LmsSubmitForm';

function SubmitPage() {
  const { error, jwt, deepLinkReturnUrl } = useSelector(state => state.getJwtAndDeeplinkReturnUrl);

  return (
    <>
      <LoadingSpinner customClass={styles.loading} />
      {!error && jwt && deepLinkReturnUrl && <LmsSubmitForm jwt={jwt} url={deepLinkReturnUrl} />}
    </>
  );
}

export default SubmitPage;
