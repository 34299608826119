import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './InputLabel.scss';

function InputLabel({ label, labelSub, labelHidden, id, required, isValid, validationMessage, disabled, children }) {
  return (
    <div className={classnames(styles.wrap, disabled && styles.disabled)}>
      <label className={classnames(styles.label, { [styles.a11yHide]: labelHidden })} htmlFor={id}>
        <p className={styles.labelPara}>{label}</p>
        {labelSub && <p className={styles.labelSubPara}>{labelSub}</p>}
        <span className={styles.a11yHide}>{required && 'required'}</span>
      </label>
      {children}
      {/* Only show the error message markup if there is one */}
      {validationMessage && (
        <p
          id={`${id}-message`}
          className={classnames(styles.validationMessage, {
            [styles.valid]: isValid === true,
            [styles.invalid]: isValid === false,
            [styles.warning]: isValid === 'warning'
          })}
        >
          {validationMessage}
        </p>
      )}{' '}
    </div>
  );
}

InputLabel.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** Set this to true to stop the cursor changing to pointer on hover  */
  disabled: PropTypes.bool,
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  required: PropTypes.bool,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** extra supporting label text */
  labelSub: PropTypes.string,
  /** The input label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** if undefined input has not been validated, if true/false svg icon and colors are applied via css */
  isValid: PropTypes.oneOf([true, false, 'warning']),
  /** Normally an error message but can also be passed on a successful validation */
  validationMessage: PropTypes.string,
  /** Either TextInput or TextInputWithButton */
  children: PropTypes.node.isRequired
};

export default InputLabel;
