import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationSingleItem from './ConfirmationSingleItem.js';
import {
  setConfirmedProducts,
  setConfirmedProductsBackup,
  setStartAndEndPage
} from '../../../redux/actions/ngiContentSelectorActions.js';
import { PLATFORMS } from '../../../../sharedNodeBrowser/constants.js';

function ConfirmationProductList() {
  const dispatch = useDispatch();
  const { products } = useSelector(state => state.getLinkedProducts);
  const { lmsConfig, confirmedProducts, confirmedProductsBackup } = useSelector(state => state.ngiContentSelector);

  const handleOnCheckboxClick = selectedProduct => {
    const targetProduct = confirmedProducts.find(p => p.isbnLaunch === selectedProduct.isbnLaunch);
    const productFromBackUp = confirmedProductsBackup.find(
      product => product.isbnLaunch === selectedProduct.isbnLaunch
    );
    if (targetProduct) {
      if (productFromBackUp)
        // replace product from backup with current from confirmed
        dispatch(
          setConfirmedProductsBackup(
            confirmedProductsBackup.map(backUpProduct => {
              if (backUpProduct.isbnLaunch === selectedProduct.isbnLaunch) {
                return { ...targetProduct };
              }
              return backUpProduct;
            })
          )
        );
      else dispatch(setConfirmedProductsBackup([...confirmedProductsBackup, selectedProduct]));
      dispatch(
        setConfirmedProducts(confirmedProducts.filter(product => product.isbnLaunch !== selectedProduct.isbnLaunch))
      );
    } else if (productFromBackUp) {
      dispatch(setConfirmedProducts([...confirmedProducts, productFromBackUp]));
    } else {
      dispatch(setConfirmedProducts([...confirmedProducts, selectedProduct]));
      dispatch(setConfirmedProductsBackup([...confirmedProductsBackup, selectedProduct]));
    }
  };

  const handleOnRadioChange = selectedProduct => {
    dispatch(setConfirmedProducts([{ ...selectedProduct, addContentItems: false, addGradeItems: false }]));
  };

  const handleOnAddGradeItemsToggleChange = selectedProduct => {
    const index = confirmedProducts.findIndex(p => p.isbnLaunch === selectedProduct.isbnLaunch);
    if (index === -1) return;
    const newConfirmedProducts = [...confirmedProducts];
    newConfirmedProducts[index].addGradeItems = !newConfirmedProducts[index].addGradeItems;
    dispatch(setConfirmedProducts(newConfirmedProducts));
  };

  const handleOnAddContentItemsToggleChange = selectedProduct => {
    const targetConfirmedProduct = confirmedProducts.find(p => p.isbnLaunch === selectedProduct.isbnLaunch);
    const toggleValue = !!targetConfirmedProduct.addContentItems;

    if (!targetConfirmedProduct) return;

    if (!toggleValue) {
      if (targetConfirmedProduct.platform === PLATFORMS.VST && targetConfirmedProduct.isChecked === undefined) {
        targetConfirmedProduct.vstUnits = [
          ...targetConfirmedProduct.vstUnits.map(unit => {
            unit.isChecked = true;
            unit.vstLessons = unit.vstLessons.map(lesson => {
              lesson.isChecked = true;
              lesson.vstActivities = lesson.vstActivities.map(activity => {
                activity.isChecked = true;
                return activity;
              });
              return lesson;
            });
            return unit;
          })
        ];
        targetConfirmedProduct.isChecked = true;
        if (targetConfirmedProduct.userType === 'TEACHER') targetConfirmedProduct.includeOnlineClassroom = true;
      } else if (targetConfirmedProduct.platform === PLATFORMS.OLB) {
        if (!targetConfirmedProduct?.selectedStartPage && !targetConfirmedProduct?.selectedEndPage)
          dispatch(
            setStartAndEndPage({
              isbn: selectedProduct.isbnLaunch,
              start: selectedProduct.olbDetails.startPage,
              end: selectedProduct.olbDetails.endPage
            })
          );
      }
    }

    targetConfirmedProduct.addContentItems = !toggleValue;

    dispatch(
      setConfirmedProducts(
        JSON.parse(
          JSON.stringify(
            confirmedProducts.map(product =>
              product.isbnLaunch === targetConfirmedProduct.isbnLaunch ? targetConfirmedProduct : product
            )
          )
        )
      )
    );
    dispatch(
      setConfirmedProductsBackup(
        confirmedProductsBackup.map(product =>
          product.isbnLaunch === targetConfirmedProduct.isbnLaunch ? targetConfirmedProduct : product
        )
      )
    );
  };

  return (
    <ul>
      {products.map(product => (
        <ConfirmationSingleItem
          product={product}
          key={product.isbnLaunch}
          showCheckbox={lmsConfig.acceptMultiple || products.length > 1}
          isChecked={confirmedProducts.findIndex(p => p.isbnLaunch === product.isbnLaunch) !== -1}
          isAddDeeplinksChecked={
            confirmedProducts.find(p => p.isbnLaunch === product.isbnLaunch)?.addContentItems || false
          }
          isAddGradesChecked={confirmedProducts.find(p => p.isbnLaunch === product.isbnLaunch)?.addGradeItems || false}
          onCheckboxClick={handleOnCheckboxClick}
          onRadioChange={handleOnRadioChange}
          alwaysShowToggles={products.length > 1 && lmsConfig.acceptMultiple === false}
          onAddGradeItemsToggleChange={handleOnAddGradeItemsToggleChange}
          onAddContentItemsToggleChange={handleOnAddContentItemsToggleChange}
        />
      ))}
    </ul>
  );
}

export default ConfirmationProductList;
