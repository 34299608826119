import React from 'react';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import styles from './Header.scss';

function ContentSelectorHeader() {
  const logo = GLYPHS.OUP_LOGO_BLUE;

  return (
    <div className={styles.header}>
      <SVGIcon className={styles.logo} glyph={logo} />
    </div>
  );
}

export default ContentSelectorHeader;
