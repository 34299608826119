import React from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import Badge from '../../../components/Badge/Badge';
import colors from '../../../globals/colors';
import styles from './SelectionFooter.scss';

function SelectionFooter({ selectedCount, onClearClick, onNextClick }) {
  return (
    <div className={styles.container}>
      {selectedCount > 0 && (
        <div className={styles.select}>
          <span>
            <Badge backgroundColor={colors.PRIMARY_BLUE} value={selectedCount} /> Selected
          </span>
          <div className={styles.clear}>
            <Button
              variant="transparent"
              text="Clear selection"
              size="small"
              hideText={false}
              onClick={onClearClick}
              disabled={selectedCount === 0}
            />
          </div>
        </div>
      )}
      <div className={styles.next}>
        <Button variant="filled" text="Next" size="base" onClick={onNextClick} disabled={selectedCount === 0} />
      </div>
    </div>
  );
}

SelectionFooter.propTypes = {
  selectedCount: PropTypes.number,
  onClearClick: PropTypes.func,
  onNextClick: PropTypes.func
};

export default SelectionFooter;
